<template>
  <div class="main-box">
    <el-form class="form-inline" :inline="true">
      <el-form-item label="">
        <el-input v-model="search.keywords" placeholder="请输入姓名/手机号" size="small" clearable>
          <el-button slot="append" class="iv-search" size="small" type="primary" @click="handleSearch">搜索</el-button>
        </el-input>
      </el-form-item>
    </el-form>
    <div class="mg-bt-10">
      <el-button type="danger" size="small" icon="el-icon-delete" @click="delGroup()">删除</el-button>
    </div>
    <el-table
            :data="tableData"
            ref="rfTable"
            style="width: 100%"
            size="mini"
            @selection-change="handleSelectionChange">
      <el-table-column
              type="selection"
              align="center"
              width="55">
      </el-table-column>
      <el-table-column
              prop="name"
              label="姓名">
      </el-table-column>
      <el-table-column
              prop="mobile"
              label="手机号"
              align="center">
      </el-table-column>
      <el-table-column
              prop="remark"
              label="需求描述"
              align="center">
      </el-table-column>
      <el-table-column
              prop="ftime"
              label="添加时间"
              align="center"
              width="140">
      </el-table-column>
    </el-table>
    <el-pagination
            background
            @current-change="handleCurrentChange"
            @size-change="handleSizeChange"
            layout="total, sizes, prev, pager, next, jumper"
            :page-sizes="[10, 20, 30, 40]"
            :total="search.total">
    </el-pagination>
  </div>
</template>
<script>

  export default {
    components: {
    },
    data() {
      return {
        info: false,
        source_domain: this.config.SOURCE_DOMAIN,
        search: {
          current_page: 1,
          total: 0,
          limit: 15,
          keywords: ''
        },
        tableData: []
      };
    },
    created() {
      this.getList();
    },
    mounted() {
    },
    computed: {
    },
    methods: {
      getList() {
        var that = this
        that.showLoading()
        var param = {
          page: that.search.current_page,
          page_size: that.search.limit,
          keywords: that.search.keywords
        }
        this.$api.shop.bookIndex(param, function (res) {
          that.hideLoading()
          if(res.errcode == 0) {
            that.tableData = res.data.data
            that.search.current_page = res.data.current_page
            that.search.total = res.data.total
          } else {
            that.fail(res.errmsg)
          }
        })
      },
      delGroup() {
        var that = this
        var sel = this.$refs.rfTable.selection
        if(sel.length<1) {
          that.tips('请选择删除项')
          return false
        }
        var ids = []
        sel.forEach(function (item) {
          ids.push(item.id)
        })
        this.$confirm('确认要删除这些数据吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$api.shop.bookDel({ids: ids},function (res) {
            if(res.errcode == 0) {
              that.success('删除成功')
              that.getList()
            } else {
              that.fail(res.errmsg)
            }
          })
        }).catch(() => {

        });
      },
      handleSearch() {
        this.search.current_page = 1
        this.getList()
      },
      handleSelectionChange(rows) {
      },
      handleCurrentChange(val) {
        this.search.current_page = val
        this.getList()
      },
      handleSizeChange(val) {
        this.search.current_page = 1
        this.search.limit = val
        this.getList()
      }
    }
  };
</script>
<style scoped>
</style>
